import { useEffect, useState } from 'react'

const useTimeSpentInSection = (sectionId: string) => {
  const [timeSpent, setTimeSpent] = useState<number>(0)
  const [startTime, setStartTime] = useState<number | null>(null)

  useEffect(() => {
    const section = document.getElementById(sectionId)
    if (!section) return

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Start timer when section becomes visible
          if (startTime === null) {
            setStartTime(Date.now())
          }
        } else {
          // Stop timer when section is not visible
          if (startTime !== null) {
            setTimeSpent((prevTime) => prevTime + (Date.now() - startTime))
            setStartTime(null)
          }
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
    })
    observer.observe(section)

    return () => {
      observer.unobserve(section)
    }
  }, [sectionId, startTime])

  return timeSpent
}

export default useTimeSpentInSection
