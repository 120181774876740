import styled from 'styled-components'
import { screens } from '../../../types/screens'

export const Container = styled.header`
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`

export const Content = styled.div<{ active?: boolean }>`
  width: 100%;
  height: auto;
  background-color: ${(props) => props.theme.colors.black};
  border-bottom: 1px solid ${(props) => props.theme.colors.white};
  display: grid;
  align-items: center;
  grid-template-columns:
    minmax(1.5rem, 1fr) minmax(auto, 2fr) minmax(auto, 3fr) minmax(auto, 7fr)
    minmax(1.5rem, 1fr);
  grid-template-rows: 5.5rem;
  grid-template-areas: '..... logo description links .....';

  ${screens.laptop`
      grid-template-rows: 5rem;
    `}

  ${screens.tablet`
      grid-template-columns:
      minmax(1.25rem, .5fr) repeat(2, minmax(auto, 4.5fr)) minmax(1.25rem, .5fr);
      grid-template-rows: 4.5rem;
      grid-template-areas: '..... logo icon .....'
    `}

  ${screens.mobile`
    grid-template-columns:
    minmax(1.5rem, 1fr) repeat(2, minmax(auto, 5fr)) minmax(1.5rem, 1fr);
    grid-template-rows: 4.5rem;
    grid-template-areas: '..... logo icon .....'
    `}
`

export const LogoImage = styled.a<{ active?: boolean }>`
  all: unset;
  width: fit-content;
  grid-area: logo;
  display: flex;
  align-items: center;
  gap: 0.875rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  & > :first-child {
    width: ${(props) => (props.active ? '0' : '2.25rem')};
    height: 1px;
    background: ${(props) => props.theme.colors.white};
    transition: all 0.5s ease-in-out;
  }

  & > p {
    font-size: 1.125rem;
    font-family: ${(props) => props.theme.fonts.primary};
    color: ${(props) => props.theme.colors.white};
  }

  & > :last-child {
    width: ${(props) => (props.active ? '2.25rem' : '0')};
    height: 1px;
    background: ${(props) => props.theme.colors.white};
    transition: all 0.5s ease-in-out;
  }

  &:hover {
    & > :first-child {
      width: 0;
    }
    & > :last-child {
      width: 2.25rem;
    }
  }

  ${screens.tablet`
    width: 10rem;    
    margin-left: -.5rem
    `}

  ${screens.mobile`
    width: 8rem;    
    margin-left: -.5rem
    `}
`

export const Description = styled.p`
  grid-area: description;
  font-size: 0.75rem;
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colors.white};

  ${screens.tablet`
        display: none;
    `}

  ${screens.mobile`
        display: none;
    `}
`

export const Links = styled.div`
  grid-area: links;
  display: flex;
  gap: 3rem;
  justify-content: flex-end;
  transition: all 0.1s ease-in-out;

  ${screens.laptop`
      gap: 2rem;
    `}

  ${screens.tablet`
        display: none;
    `}

  ${screens.mobile`
        display: none;
    `}
`

export const Link = styled.a`
  all: unset;
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colors.white};
  font-weight: 400;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`

export const Icon = styled.div`
  grid-area: icon;
  justify-self: flex-end;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  display: none;

  ${screens.tablet`
         display: block;
         font-size: 2.5rem;
    `}

  ${screens.mobile`
         display: block;
         font-size: 2rem;
    `}
`

export const MobileOptions = styled.div<{ active?: boolean }>`
  width: 100%;
  height: ${(props) => (props.active ? 'calc(100vh - 4rem)' : '0rem')};
  position: absolute;
  background-color: ${(props) => props.theme.colors.black};
  top: 100%;
  left: 0;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(1.5rem, 1fr) minmax(auto, 10fr) minmax(
      1.5rem,
      1fr
    );
  grid-template-rows: auto;
  grid-template-areas: '..... mobileActions .....';
`

export const MobileActions = styled.div`
  grid-area: mobileActions;
  display: flex;
  gap: 2.5rem;
  flex-direction: column;
  padding: 2rem 0;
`
