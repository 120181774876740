import styled from 'styled-components'
import { screens } from '../../../../../types/screens'

export const Container = styled.section<{
  translateOne: number
  translateTwo: number
  translateThree: number
}>`
  width: 100%;
  height: 70vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.black};

  & > p {
    font-size: 3.75rem;
    font-weight: 600;
    font-family: ${(props) => props.theme.fonts.secondary};
    color: ${(props) => props.theme.colors.white};
    line-height: 5rem;
  }

  & > :first-child {
    transform: translateX(${(props) => props.translateOne}%);
    transition:
      opacity 0.8s ease-out,
      transform 0.8s ease-out;
  }

  & > :nth-child(2) {
    transform: translateX(${(props) => props.translateTwo}%);
    transition:
      opacity 0.8s ease-out,
      transform 0.8s ease-out;
  }

  & > :last-child {
    transform: translateX(${(props) => props.translateThree}%);
    transition:
      opacity 0.8s ease-out,
      transform 0.8s ease-out;
  }

  ${screens.tablet`
      padding: 5rem 0;

        & > p {
          font-size: 2.5rem;
          line-height: normal;
        }
    `}

  ${screens.mobile`
      padding: 5rem 0;

        & > p {
          font-size: 1.75rem;
          line-height: normal;
        }
    `}
`
