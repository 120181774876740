import { useEffect } from 'react'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { LeadModel } from '../../../../../types/leadModel'
import { FaArrowUp } from 'react-icons/fa'
import {
  Action,
  Container,
  Content,
  Description,
  Image,
  Text,
  Title,
} from './SectionSeven.styles'
import { AdvantageItem } from '../../../../commons/AdvantageItem'

import SectionSevenImage from '@/../../src/assets/images/section-seven-image.png'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionSeven = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-five')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-seven',
        'section-seven-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="section-seven">
      <Title>
        Por que <br /> somos <br />
        sua melhor <br /> opção?
      </Title>
      <Description>
        <Text>
          Além de um site responsivo, 100% personalizado, gerenciamento
          facilitado e muuuito mais, quer saber o que vai fazer você se
          destacar?
        </Text>
        <Action
          href="https://wa.me/5511999998888?text=Olá,%20estou%20interessado%20em%20orçamentos%20sobre%20soluções%20digitais,%20como%20Landing%20Pages,%20Intranet%20e%20outros%20serviços."
          target="__blank"
          onClick={() => {
            HandleLead('click', 'section-seven', 'section-seven-whatsapp-link')
          }}
          onMouseOver={() => {
            HandleLead(
              'mouse-over',
              'section-seven',
              'section-seven-whatsapp-link'
            )
          }}
        >
          SOLICITAR ORÇAMENTO
          <FaArrowUp />
        </Action>
      </Description>
      <Content>
        <AdvantageItem
          title="//Design e UX Personalizados"
          content="Layouts modernos, focados em experiência do usuário, garantindo páginas e sistemas atraentes e intuitivas."
        />
        <AdvantageItem
          title="//Construção Completa (FrontEnd, BackEnd, Banco de Dados)"
          content="Desenvolvimento de ponta a ponta, garantindo que todos os componentes do sistema funcionem perfeitamente em conjunto."
        />
        <AdvantageItem
          title="//Hospedagem"
          content="Garantimos a hospedagem do seu site ou sistema em um ambiente seguro e estável. Planos Econômicos ou de Alta Disponibilidade. "
        />
        <AdvantageItem
          title="//Monitoramento Contínuo"
          content="Acompanhamento do desempenho do seu sistema/landing page 24/7 para garantir alta disponibilidade."
        />
      </Content>
      <Image src={SectionSevenImage} alt="section-seven-image" />
    </Container>
  )
}
