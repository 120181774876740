import styled from 'styled-components'
import { screens } from '../../../types/screens'

export const Container = styled.div<{ isBlue?: boolean }>`
  width: 40rem;
  height: 33.875rem;
  overflow: hidden;
  position: relative;
  border-radius: 1rem;
  background: ${(props) =>
    props.isBlue ? props.theme.colors.primary : props.theme.colors.secondary};

  ${screens.laptop`
      width: 27rem;
      height: 38rem;
    `}

  ${screens.tablet`
      width: 100%;
      height: 30rem;
    `}

  ${screens.mobile`
      width: 100%;
      height: 30rem;
    `}
`

export const Close = styled.div<{ active: boolean }>`
  width: 4rem;
  height: 4rem;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.quinary};
  position: absolute;
  top: 2rem;
  right: 2.25rem;
  cursor: pointer;

  & > svg {
    font-size: 2.5rem;
    color: ${(props) => props.theme.colors.black};
    transition: all 0.5s ease-in-out;
    transform: rotate(${(props) => (props.active ? '0' : '45deg')});
  }

  ${screens.mobile`
      width: 3rem;
      height: 3rem;

   & > svg {
      font-size: 1.75rem;
      transition: all 0.5s ease-in-out;
    }
    `}
`

export const Title = styled.h1<{ active: boolean; isBlue?: boolean }>`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 3rem;
  font-weight: bold;
  color: ${(props) =>
    props.isBlue ? props.theme.colors.white : props.theme.colors.black};
  position: absolute;
  bottom: 3.25rem;
  left: 2.25rem;
  max-width: 29rem;
  opacity: ${(props) => (props.active ? 0 : 1)};
  transition: all 0.5s ease-in-out;

  ${screens.laptop`
      font-size: 2.125rem;
    `}

  ${screens.tablet`
      bottom: 2.25rem;
      left: 1.5rem;
      font-size: 2rem;
      max-width: 20rem;
    `}

  ${screens.mobile`
      font-size: 1.75rem;
      max-width: 17rem;
    `}
`

export const Content = styled.div<{ active: boolean; isBlue?: boolean }>`
  position: absolute;
  max-width: 85%;
  display: flex;
  flex-direction: column;
  gap: 1.625rem;
  bottom: 3.25rem;
  left: 2.25rem;
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: all 0.5s ease-in-out;

  p {
    font-family: ${(props) => props.theme.fonts.secondary};
    font-size: 1.125rem;
    font-weight: 600;
    color: ${(props) =>
      props.isBlue ? props.theme.colors.white : props.theme.colors.black};
    margin-bottom: 0.5rem;
  }

  ul {
    list-style-type: disc;
    padding-left: 1rem;

    & > li {
      font-family: ${(props) => props.theme.fonts.secondary};
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: ${(props) =>
        props.isBlue ? props.theme.colors.white : '#585858'};
    }
  }

  ${screens.tablet`
       p {
        font-size: 1.125rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
  }

      ul {
        padding-left: 1rem;

      & > li {
        font-size: 0.875rem;
        line-height: normal;
    }
  }
    `}

  ${screens.mobile`
      bottom: 2.25rem;
      left: 1.5rem;
      gap: 1rem;

       p {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
  }

      ul {
        padding-left: 1rem;

      & > li {
        font-size: 0.75rem;
        line-height: normal;
    }
  }
    `}
`
