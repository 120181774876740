import { LeadModel } from '../../../types/leadModel'
import {
  // Contact,
  Container,
  // Data,
  // Line,
  // Link,
  // Links,
  // Socials,
  Text,
} from './Footer.styles'

// import Logo from '@/../../src/assets/images/logo.png'
// import { RiMailLine } from 'react-icons/ri'
import useTimeSpentInSection from '../../../hooks/useTimeSpentInSection'
import { useEffect } from 'react'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const Footer = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('footer')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead('time-spent', 'footer', 'footer-container', timeSpent / 1000)
  }, [timeSpent])

  return (
    <Container id="footer">
      {/* <Contact>CONTATO</Contact>
      <Line />
      <Data>
        <p>SÃO PAULO - SP</p>
        <Links>
          <Link>BRBW@GMAIL.COM</Link>
          <Socials>
            <Link>INSTAGRAM</Link>
            <Link>LINKEDIN</Link>
          </Socials>
        </Links>
      </Data> */}
      <Text>
        VAMOS CONSTRUIR <br /> ALGO <span>INCRÍVEL</span> JUNTOS?
      </Text>
    </Container>
  )
}
