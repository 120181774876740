import { useEffect } from 'react'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { LeadModel } from '../../../../../types/leadModel'
import { Container, Content, Services, Title } from './SectionSix.styles'
import { ItemService } from '../../../../commons/ServiceItem'
interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionSix = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-six')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-six',
        'section-six-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="section-six">
      <Content>
        <Title>SERVIÇOS</Title>
        <Services>
          <ItemService
            title="Landing Pages Personalizadas para Converter Mais"
            description={
              <>
                <div>
                  <p>Design e UX Personalizados</p>
                  <ul>
                    <li>
                      Layouts modernos, focados em experiência do usuário,
                      garantindo páginas atraentes e intuitivas.
                    </li>
                  </ul>
                </div>
                <div>
                  <p>Desenvolvimento Completo</p>
                  <ul>
                    <li>
                      Usamos as melhores tecnologias para criar páginas que
                      funcionam bem em qualquer dispositivo, como celulares e
                      computadores.
                    </li>
                  </ul>
                </div>
                <div>
                  <p>Coleta de Métricas de Usabilidade</p>
                  <ul>
                    <li>
                      Integração de ferramentas de análise para monitorar o
                      comportamento do usuário: profundidade de scroll, cliques
                      e tempo de navegação.
                    </li>
                  </ul>
                </div>
                <div>
                  <p>Ajustes e Manutenção</p>
                  <ul>
                    <li>
                      Atualizações e melhorias contínuas para manter a página
                      sempre em alta performance.
                    </li>
                  </ul>
                </div>
              </>
            }
            onClick={() => {
              HandleLead('click', 'section-six', 'section-six-service-box-one')
            }}
            onMouseOver={() => {
              HandleLead(
                'mouse-over',
                'section-six',
                'section-six-service-box-one'
              )
            }}
          />
          <ItemService
            title="Sistemas Intranet para Automatizar e Integrar Sua Equipe"
            description={
              <>
                <div>
                  <p>Design e UX Personalizados</p>
                  <ul>
                    <li>
                      Layouts modernos, focados em experiência do usuário,
                      garantindo páginas atraentes e intuitivas.
                    </li>
                  </ul>
                </div>
                <div>
                  <p>Construção Completa (FrontEnd, BackEnd, Banco de Dados)</p>
                  <ul>
                    <li>
                      Desenvolvimento de ponta a ponta, garantindo que todos os
                      componentes do sistema funcionem perfeitamente em
                      conjunto.
                    </li>
                  </ul>
                </div>
                <div>
                  <p>Consultoria Especializada</p>
                  <ul>
                    <li>
                      Mapeamos suas operações e as traduzimos em funcionalidades
                      tecnológicas, garantindo que sua intranet atenda
                      exatamente às suas necessidades.
                    </li>
                  </ul>
                </div>
                <div>
                  <p>Integração com Sistemas Internos e APIs Externas</p>
                  <ul>
                    <li>
                      Seu sistema sempre funcionando de forma fluida e
                      eficiente.
                    </li>
                  </ul>
                </div>
              </>
            }
            isBlue
            onClick={() => {
              HandleLead('click', 'section-six', 'section-six-service-box-two')
            }}
            onMouseOver={() => {
              HandleLead(
                'mouse-over',
                'section-six',
                'section-six-service-box-two'
              )
            }}
          />
        </Services>
      </Content>
    </Container>
  )
}
