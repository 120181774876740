import { ActiveButton, Button } from './MainButton.styles'

interface Props {
  title: string
  onClick: () => void
  onMouseOver: () => void
}

export const MainButton = ({ title, onClick, onMouseOver }: Props) => {
  return (
    <Button
      onClick={onClick}
      onMouseOver={onMouseOver}
      href="https://wa.me/5511994497142?text=Olá,%20estou%20interessado%20em%20saber%20mais%20sobre%20soluções%20digitais,%20como%20Landing%20Pages,%20Intranet%20e%20outros%20serviços."
      target="__blank"
    >
      <p>{title}</p>
      <ActiveButton />
    </Button>
  )
}
