//Arquivo para definição das variáveis de telas da aplicação

import { css } from 'styled-components'

const sizes = {
  mobile: '30rem', // 480px / 16 = 30rem
  tablet: '48rem', // 768px / 16 = 48rem
  laptop: '64rem', // 1024px / 16 = 64rem
  desktop: '80rem', // 1440px / 16 = 90rem
}

export const screens = {
  mobile: (styles: TemplateStringsArray) => css`
    @media (max-width: ${sizes.mobile}) {
      ${styles}
    }
  `,
  tablet: (styles: TemplateStringsArray) => css`
    @media (max-width: ${sizes.tablet}) {
      ${styles}
    }
  `,
  laptop: (styles: TemplateStringsArray) => css`
    @media (max-width: ${sizes.laptop}) {
      ${styles}
    }
  `,
  desktop: (styles: TemplateStringsArray) => css`
    @media (max-width: ${sizes.desktop}) {
      ${styles}
    }
  `,
}
