import { MainButton } from '../../../../commons/MainButton'

import {
  Action,
  Container,
  Content,
  Text,
  Title,
  ImageContainer,
  Image,
} from './SectionOne.styles'
import { LeadModel } from '../../../../../types/leadModel'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { useEffect } from 'react'

import { SecondaryButton } from '../../../../commons/SecondaryButton'

import SectionOneImage from '@/../../src/assets/images/section-one-image.png'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionOne = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-one')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-one',
        'section-one-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="section-one">
      <Content>
        <Title>
          O Futuro da Sua Presença <br /> <span>Digital</span> Começa Aqui
        </Title>
        <Text>
          Soluções digitais personalizadas para elevar sua marca e otimizar suas
          operações.
        </Text>
        <Action>
          <MainButton
            title="Contratar Serviço"
            onClick={() => {
              HandleLead('click', 'section-one', 'section-one-main-button')
            }}
            onMouseOver={() => {
              HandleLead('mouse-over', 'section-one', 'section-one-main-button')
            }}
          />
          <SecondaryButton
            title="Saiba Mais"
            onClick={() => {
              HandleLead('click', 'section-one', 'section-one-main-button')
            }}
            onMouseOver={() => {
              HandleLead('mouse-over', 'section-one', 'section-one-main-button')
            }}
          />
        </Action>
      </Content>
      <ImageContainer>
        <Image
          src={SectionOneImage}
          alt="section-one-image"
          onClick={() => {
            HandleLead('click', 'section-one', 'section-one-image')
          }}
          onMouseOver={() => {
            HandleLead('mouse-over', 'section-one', 'section-one-image')
          }}
        />
      </ImageContainer>
    </Container>
  )
}
