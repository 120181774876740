import styled from 'styled-components'
import { screens } from '../../../../../types/screens'

export const Container = styled.section`
  width: 100%;
  height: auto;
  padding: 7.5rem 0;
  display: grid;
  grid-template-columns:
    minmax(1.5rem, 1fr) minmax(auto, 4.5fr) minmax(11rem, 1.5fr)
    minmax(auto, 6fr) minmax(1.5rem, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    '..... title   ..... description .....'
    '..... content ..... image       .....';
  row-gap: 6.25rem;

  ${screens.tablet`
    grid-template-columns:
    minmax(1.5rem, 1fr) minmax(auto, 10fr) minmax(1.5rem, 1fr);
    grid-template-rows: repeat(4, auto);
    row-gap: 0;
    grid-template-areas:
      '..... title       .....'
      '..... description .....'
      '..... content     .....'
      '..... image       .....';
       padding: 0 0 3rem;
    `}
`

export const Title = styled.h1`
  grid-area: title;
  justify-self: flex-end;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 3.875rem;
  font-weight: 600;
  line-height: 4.375rem;
  color: ${(props) => props.theme.colors.white};

  ${screens.laptop`
      line-height: 4rem;
      justify-self: flex-start;
      font-size: 3rem;
    `}

  ${screens.tablet`
      line-height: 2.25rem;
      justify-self: flex-start;
      font-size: 2rem;
      margin: 0.75rem 0 2.75rem;

      & > br {
      display: none}
    `}

  ${screens.mobile`
       font-size: 2rem;
       margin: 0.75rem 0 2.75rem;
    `}
`

export const Description = styled.div`
  grid-area: description;
  align-self: flex-end;
`

export const Text = styled.p`
  font-size: 1rem;
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colors.secondary};
  max-width: 22rem;
  margin-bottom: 2.75rem;

  ${screens.tablet`
      max-width: 100%;
    `}
`

export const Action = styled.a`
  all: unset;
  grid-area: action;
  width: fit-content;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  gap: 1.5rem;
  font-size: 0.75rem;
  border-radius: 9999px;
  border: 1px solid ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  & > svg {
    font-size: 1rem;
    transform: rotate(45deg);
  }

  &:hover {
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.black};
  }

  ${screens.tablet`
      width: 100%;
      padding: 0;
    `}
`

export const Content = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
`

export const Image = styled.img`
  grid-area: image;
  width: 100%;
  height: 49.375rem;
  object-fit: cover;
  object-position: center;

  ${screens.tablet`
      margin-top: 3rem;
      height: 30.375rem;
    `}
`
