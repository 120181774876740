import { useEffect } from 'react'
import { LeadModel } from '../../../../../types/leadModel'
import {
  Container,
  Content,
  Icon,
  Image,
  Social,
  Text,
  Title,
} from './SectionEight.styles'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa'
import ProfileImage from '@/../../src/assets/images/avatar-profile.png'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionEight = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-eight')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-eight',
        'section-eight-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="section-eight">
      <Content>
        <Title>
          Equipe <br /> <span>BrBW.</span>
        </Title>
        <Text>
          Seu negócio precisa de uma presença online impactante, e estamos aqui
          para garantir isso. Nossa equipe reúne talentos criativos e técnicos
          que trabalham em conjunto para entregar resultados de alto nível,
          criando landing pages envolventes e sistemas de intranet
          personalizados.
          <br />
          <br />
          Oferecemos soluções completas, cuidando de todo o processo, do design
          à hospedagem tanto em cloud quando em nossos próprios servidores,
          sempre com foco em eficiência, segurança e inovação. Deixe a
          concorrência para trás e destaque-se no digital com a nossa expertise.
        </Text>
        <Social>
          <Icon
            href="https://wa.me/5511994497142?text=Olá,%20estou%20interessado%20em%20saber%20mais%20sobre%20soluções%20digitais,%20como%20Landing%20Pages,%20Intranet%20e%20outros%20serviços."
            target="__blank"
            onClick={() => {
              HandleLead(
                'click',
                'section-eight',
                'section-eight-whatsapp-link'
              )
            }}
            onMouseOver={() => {
              HandleLead(
                'mouse-over',
                'section-eight',
                'section-eight-whatsapp-link'
              )
            }}
          >
            <FaWhatsapp />
          </Icon>
          <Icon
            href=""
            target="__blank"
            onClick={() => {
              HandleLead(
                'click',
                'section-eight',
                'section-eight-instagram-link'
              )
            }}
            onMouseOver={() => {
              HandleLead(
                'mouse-over',
                'section-eight',
                'section-eight-instagram-link'
              )
            }}
          >
            <FaInstagram />
          </Icon>
          <Icon
            href=""
            target="__blank"
            onClick={() => {
              HandleLead(
                'click',
                'section-eight',
                'section-eight-linkedin-link'
              )
            }}
            onMouseOver={() => {
              HandleLead(
                'mouse-over',
                'section-eight',
                'section-eight-linkedin-link'
              )
            }}
          >
            <FaLinkedin />
          </Icon>
        </Social>
      </Content>
      <Image src={ProfileImage} />
    </Container>
  )
}
