import axios from 'axios'
import { LeadModel } from '../types/leadModel'

export const SendLeadService = async (lead: LeadModel) => {
  const url = 'https://lndback.brbw.com.br/landingpages/add_user_interaction'

  const response = await axios.post(url, lead)

  return response.data
}
