import styled, { keyframes } from 'styled-components'
import { screens } from '../../../../../types/screens'

const scroll = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
}
`

export const Container = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.black};
  flex-direction: column;
  padding: 4rem 0;
  border-top: 1px solid ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.white};
`

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 2.5rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white};
  line-height: 3rem;
  margin-bottom: 2rem;
  max-width: 30rem;
  text-align: center;

  ${screens.tablet`
      font-size: 2rem;
      line-height: 2rem;
  `}

  ${screens.mobile`
      font-size: 1.5rem;
      max-width: 20rem;
      line-height: 2rem;
  `}
`

export const CarouselWrapper = styled.div`
  width: 100%;
  height: 15.625rem;
  display: inline-flex;
  overflow-x: hidden;

  ${screens.tablet`
      height: 12.625rem;
  `}
`

export const Carousel = styled.div`
  width: max-content;
  white-space: nowrap;
  animation: ${scroll} 28s linear infinite;
  display: inline-flex;
  align-items: center;
`

export const CarouselItem = styled.div`
  width: 10.625rem;
  height: 6rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 30px 40px 0px #d4d9e833;
  border-radius: 1rem;
  margin-right: 2rem;
  transition: all 0.5s ease-in-out;

  &:hover {
    scale: 1.15;
    & > :first-child {
      filter: brightness(1.3);
    }
  }
`

export const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 6rem;
  max-height: 4rem;
  transition: all 0.5s ease-in-out;
`
