import { LeadModel } from '../../../types/leadModel'
import { Footer } from '../../commons/Footer'
import { Header } from '../../commons/Header'
import { HomeSectionEight } from './sections/SectionEight'
import { HomeSectionFive } from './sections/SectionFive'
import { HomeSectionFour } from './sections/SectionFour'
import { HomeSectionOne } from './sections/SectionOne'
import { HomeSectionSeven } from './sections/SectionSeven'
import { HomeSectionSix } from './sections/SectionSix'
import { HomeSectionThree } from './sections/SectionThree'
import { HomeSectionTwo } from './sections/SectionTwo'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeLayout = ({ SendLead, ip, userAgent }: Props) => {
  return (
    <>
      <Header SendLead={SendLead} ip={ip} userAgent={userAgent} />
      <HomeSectionOne SendLead={SendLead} ip={ip} userAgent={userAgent} />
      <HomeSectionTwo SendLead={SendLead} ip={ip} userAgent={userAgent} />
      <HomeSectionThree SendLead={SendLead} ip={ip} userAgent={userAgent} />
      <HomeSectionFour SendLead={SendLead} ip={ip} userAgent={userAgent} />
      <HomeSectionFive SendLead={SendLead} ip={ip} userAgent={userAgent} />
      <HomeSectionSix SendLead={SendLead} ip={ip} userAgent={userAgent} />
      <HomeSectionSeven SendLead={SendLead} ip={ip} userAgent={userAgent} />
      <HomeSectionEight SendLead={SendLead} ip={ip} userAgent={userAgent} />
      <Footer SendLead={SendLead} ip={ip} userAgent={userAgent} />
    </>
  )
}
