import { useEffect } from 'react'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { LeadModel } from '../../../../../types/leadModel'
import { Action, Container, Content, Logo, Title } from './SectionThree.styles'
import { FaArrowUp } from 'react-icons/fa'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionThree = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-three')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-three',
        'section-three-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="section-three">
      <Logo>BRBW</Logo>
      <Title>Nosso objetivo é ajudá-lo a fortalecer sua presença online.</Title>
      <Content>
        Seja bem-vindo ao mundo das soluções digitais! Na BrBW, entendemos as
        necessidades únicas de quem busca expandir sua presença online e
        otimizar processos internos.
        <br /> <br />
        Oferecemos serviços especializados em desenvolvimento de landing pages e
        soluções de intranet para ajudar você a alcançar seus objetivos,
        melhorando sua comunicação e aumentando sua eficiência.
      </Content>
      <Action
        href="https://wa.me/5511999998888?text=Olá,%20estou%20interessado%20em%20orçamentos%20sobre%20soluções%20digitais,%20como%20Landing%20Pages,%20Intranet%20e%20outros%20serviços."
        target="__blank"
        onClick={() => {
          HandleLead('click', 'section-three', 'section-three-whatsapp-link')
        }}
        onMouseOver={() => {
          HandleLead(
            'mouse-over',
            'section-three',
            'section-three-whatsapp-link'
          )
        }}
      >
        CONHEÇA NOSSOS SERVIÇOS
        <FaArrowUp />
      </Action>
    </Container>
  )
}
