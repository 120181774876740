import {
  Carousel,
  CarouselItem,
  CarouselWrapper,
  Container,
  Image,
  Title,
} from './SectionTwo.styles'
import { LeadModel } from '../../../../../types/leadModel'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { useEffect } from 'react'

import LogoAngular from '@/../../src/assets/images/logo-angular.png'
import LogoAWS from '@/../../src/assets/images/logo-aws.png'
import LogoC from '@/../../src/assets/images/logo-c.png'
import LogoCss from '@/../../src/assets/images/logo-css.png'
import LogoDocker from '@/../../src/assets/images/logo-docker.png'
import LogoDotNet from '@/../../src/assets/images/logo-dotnet.png'
import LogoHtml from '@/../../src/assets/images/logo-html.png'
import LogoJavascript from '@/../../src/assets/images/logo-javascript.png'
import LogoNginx from '@/../../src/assets/images/logo-nginx.png'
import LogoNode from '@/../../src/assets/images/logo-nginx.png'
import LogoPostgre from '@/../../src/assets/images/logo-postgre.png'
import LogoReact from '@/../../src/assets/images/logo-react.png'
import LogoSql from '@/../../src/assets/images/logo-sqlserver.png'
import LogoTypescript from '@/../../src/assets/images/logo-typescript.png'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionTwo = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-two')

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-two',
        'section-two-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  return (
    <Container id="section-two">
      <Title>Trabalhamos com as melhores Tecnologias do mercado</Title>
      <CarouselWrapper>
        <Carousel>
          <CarouselItem>
            <Image src={LogoHtml} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoCss} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoJavascript} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoReact} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoAngular} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoTypescript} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoNode} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoC} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoDotNet} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoSql} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoPostgre} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoDocker} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoAWS} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoNginx} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoHtml} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoCss} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoJavascript} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoReact} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoAngular} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoTypescript} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoNode} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoC} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoDotNet} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoSql} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoPostgre} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoDocker} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoAWS} />
          </CarouselItem>
          <CarouselItem>
            <Image src={LogoNginx} />
          </CarouselItem>
        </Carousel>
      </CarouselWrapper>
    </Container>
  )
}
