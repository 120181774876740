import { ReactElement, useState } from 'react'
import { Close, Container, Content, Title } from './ServiceItem.styles'
import { IoCloseOutline } from 'react-icons/io5'

interface Props {
  title: string
  description: ReactElement
  isBlue?: boolean
  action?: ReactElement
  onClick: () => void
  onMouseOver: () => void
}

export const ItemService = ({
  title,
  description,
  isBlue,
  onClick,
  onMouseOver,
}: Props) => {
  const [active, setActive] = useState(false)

  return (
    <Container
      onClick={onClick}
      onMouseOver={() => {
        onMouseOver()
        setActive(true)
      }}
      onMouseOut={() => {
        setActive(false)
      }}
      isBlue={isBlue}
    >
      <Close active={active}>
        <IoCloseOutline onClick={() => setActive(active ? false : true)} />
      </Close>
      <Title active={active} isBlue={isBlue}>
        {title}
      </Title>
      <Content active={active} isBlue={isBlue}>
        {description}
      </Content>
    </Container>
  )
}
