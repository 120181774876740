import * as UserIpServices from '../services/GetUserIpService'

export const useUserIp = () => {
  const GetUserIp = async () => {
    try {
      const response = await UserIpServices.GetUserIp()
      return response as string
    } catch (error) {
      console.error('Erro ao obter o IP:', error)
      return ''
    }
  }

  return {
    GetUserIp,
  }
}
