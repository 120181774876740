import { Container, Content, Title } from './AdvantageItem.styles'

interface Props {
  title: string
  content: string
}

export const AdvantageItem = ({ title, content }: Props) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </Container>
  )
}
