import styled from 'styled-components'
import { screens } from '../../../types/screens'

export const Container = styled.div<{ colorful?: boolean }>`
  width: auto;
  height: auto;
  max-width: 26.625rem;
  display: flex;
  flex-direction: column;

  ${screens.tablet`
      max-width: 100%;
    `}
`

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 1.5rem;
  font-weight: 300;
  color: ${(props) => props.theme.colors.quinary};
  margin: 3.5rem 0 0.75rem;
`

export const Content = styled.p`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 0.875rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.quinary};
  line-height: 1.5rem;
`
