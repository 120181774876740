import styled from 'styled-components'
import { screens } from '../../../../../types/screens'

export const Container = styled.section`
  width: 100%;
  height: auto;
  padding: 7.25rem 0;
  display: grid;
  grid-template-columns: minmax(1.5rem, 1fr) minmax(auto, 12fr) minmax(
      1.5rem,
      1fr
    );
  grid-template-rows: auto;
  grid-template-areas: '..... content .....';

  ${screens.laptop`
   padding: 6rem 0;
      grid-template-columns:
      minmax(1.5rem, 1fr) minmax(auto, 12fr) minmax(1.5rem, 1fr);
    `}

  ${screens.tablet`
   padding: 4rem 0;
      grid-template-columns:
      minmax(1.5rem, 1fr) minmax(auto, 10fr) minmax(1.5rem, 1fr);
    `}
`

export const Content = styled.div`
  grid-area: content;
  width: 100%;
`

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 1.125rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 4.5rem;

  ${screens.laptop`
      font-size: 2rem;
    `}

  ${screens.tablet`
      font-size: 1.875rem;
      max-width: 28rem;
      margin-bottom: 2.5rem;
    `}

  ${screens.mobile`
      font-size: 1.5rem;
      max-width: 20rem;
    `}
`

export const Services = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.25rem;

  ${screens.tablet`
        flex-direction: column;
    `}

  ${screens.mobile`
        flex-direction: column;
    `}
`
