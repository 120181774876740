/// <reference lib="dom" />

import { useEffect, useRef, useState } from 'react'
import { Container } from './SectionFour.styles'
import { LeadModel } from '../../../../../types/leadModel'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionFour = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-four')

  const [translateOne, setTranslateOne] = useState(-100) // A frase começa fora da tela à esquerda
  const [translateTwo, setTranslateTwo] = useState(100)
  const [translateThree, setTranslateThree] = useState(-100)
  const sectionRef = useRef<HTMLDivElement>(null)

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-four',
        'section-four-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const sectionTop = sectionRef.current.getBoundingClientRect().top
        const windowHeight = window.innerHeight

        if (sectionTop < windowHeight && sectionTop > 0) {
          const visiblePercentage = 1 - sectionTop / windowHeight
          setTranslateOne(-100 + visiblePercentage * 100)
          setTranslateTwo(100 - visiblePercentage * 100)
          setTranslateThree(-100 + visiblePercentage * 100)
        } else if (sectionTop < 0) {
          setTranslateOne(10)
          setTranslateTwo(-10)
          setTranslateThree(10)
        } else {
          setTranslateOne(-100)
          setTranslateTwo(100)
          setTranslateThree(-100)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Container
      id="section-four"
      ref={sectionRef}
      translateOne={translateOne}
      translateTwo={translateTwo}
      translateThree={translateThree}
    >
      <p>ONDE TECNOLOGIA</p>
      <p>E PERFORMANCE</p>
      <p>SE ENCONTRAM</p>
    </Container>
  )
}
