import styled from 'styled-components'
import { screens } from '../../../../../types/screens'

export const Container = styled.section`
  width: 100%;
  height: auto;
  padding: 7rem 0 3.25rem;
  display: grid;
  grid-template-columns:
    minmax(1.5rem, 1fr) minmax(auto, 12fr)
    minmax(1.5rem, 1fr);
  grid-template-rows: auto;
  grid-template-areas: '..... image .....';

  ${screens.laptop`
   
    `}

  ${screens.tablet`
     
    `}

  ${screens.mobile`
     padding: 0;
    `}
`

export const Image = styled.img<{ opacity: number }>`
  grid-area: image;
  width: 100%;
  height: auto;
  opacity: ${(props) => props.opacity};
  transition: all 0.8s ease-in-out;
`
