import styled from 'styled-components'
import { screens } from '../../../../../types/screens'

export const Container = styled.section`
  width: 100%;
  height: auto;
  padding: 6.625rem 0;
  display: grid;
  background: ${(props) => props.theme.colors.quinary};
  grid-template-columns: minmax(1.5rem, 1fr) repeat(2, minmax(auto, 6fr)) minmax(
      1.5rem,
      1fr
    );
  grid-template-rows: auto;
  grid-template-areas: '..... image content .....';

  ${screens.laptop`
       padding: 3rem 0;
    `}

  ${screens.tablet`
      padding: 5rem 0;
      grid-template-columns: minmax(1.5rem, 1fr) minmax(auto, 10fr) minmax(
      1.5rem,
      1fr
    );
      grid-template-rows: auto;
      grid-template-areas: 
      '..... image .....'
      '..... content .....';
      row-gap: 2rem;
    `}
`

export const Content = styled.div`
  grid-area: content;
  align-self: center;
  max-width: 25rem;

  ${screens.tablet`
      max-width: 100%;
    `}
`

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 4.5rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 1.875rem;
  line-height: 4.75rem;

  & > span {
    color: ${(props) => props.theme.colors.primary};
  }

  ${screens.laptop`
      font-size: 2.5rem;
      line-height: 3rem;
    `}

  ${screens.tablet`
      font-size: 2.5rem;
      & > br {
        display: none;
      }
    `}

  ${screens.mobile`
      font-size: 2rem;
    `}
`

export const Text = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 0.875rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
  margin: 1.875rem 0 4.375rem;
  line-height: 1.25rem;

  & > span {
    color: ${(props) => props.theme.colors.primary};
  }

  ${screens.laptop`
      font-size: 1.25rem;
    `}

  ${screens.tablet`
      font-size: 1.25rem;
      line-height: normal;
    `}

  ${screens.mobile`
      font-size: 1.125rem;
    `}
`

export const Social = styled.div`
  display: flex;
  gap: 1.875rem;
  align-items: center;
`

export const Icon = styled.a`
  font-size: 1.5rem;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
`

export const Image = styled.img`
  grid-area: image;
  width: 70%;
  height: auto;
  align-self: center;
  justify-self: center;
  filter: brightness(0) saturate(100%) invert(18%) sepia(60%) saturate(5793%)
    hue-rotate(218deg) brightness(96%) contrast(97%);
`
