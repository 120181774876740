import styled from 'styled-components'
import { screens } from '../../../../../types/screens'

export const Container = styled.section`
  width: 100%;
  padding: 7rem 0;
  background: ${(props) => props.theme.colors.black};
  display: grid;
  grid-template-columns:
    minmax(1.5rem, 1fr) minmax(auto, 5fr) minmax(8rem, 2fr)
    minmax(auto, 5fr) minmax(1.5rem, 1fr);

  grid-template-rows: auto 12.5rem auto;

  grid-template-areas:
    '..... logo  ..... .....   .....'
    '..... title ..... .....   .....'
    '..... title ..... content .....'
    '..... ..... ..... action  .....';

  ${screens.tablet`
  padding: 5rem 0 0;
      grid-template-columns:
    minmax(1.5rem, 1fr) minmax(auto, 10fr) minmax(1.5rem, 1fr);
  grid-template-rows: repeat(4, auto);

  grid-template-areas:
    '..... logo    .....'
    '..... title   .....'
    '..... content .....'
    '..... action  .....';
    `}
`

export const Logo = styled.p`
  grid-area: logo;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 1.125rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 1rem;
`

export const Title = styled.h1`
  grid-area: title;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 3.75rem;
  font-weight: 600;
  line-height: 4.375rem;
  color: ${(props) => props.theme.colors.white};
  max-width: 32rem;
  margin-bottom: 4.75rem;

  ${screens.laptop`
      font-size: 3rem;
      margin-bottom: 2rem;
      line-height: normal;
    `}

  ${screens.tablet`
      font-size: 2.25rem;
      margin-bottom: 2rem;
      line-height: normal;
    `}

  ${screens.mobile`
      font-size: 2rem;
      line-height: normal;
    `}
`

export const Content = styled.p`
  grid-area: content;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 1rem;
  color: ${(props) => props.theme.colors.white};
  max-width: 29rem;
  margin-bottom: 4.125rem;

  ${screens.tablet`
      margin-bottom: 2rem;
    `}
`

export const Action = styled.a`
  all: unset;
  grid-area: action;
  width: fit-content;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  gap: 1.5rem;
  font-size: 0.75rem;
  border-radius: 9999px;
  border: 1px solid ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  & > svg {
    font-size: 1rem;
    transform: rotate(45deg);
  }

  &:hover {
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.black};
  }

  ${screens.mobile`
      width: 100%;
      padding: 0;
    `}
`
