import { useState } from 'react'
import {
  Container,
  Content,
  Description,
  Icon,
  Link,
  Links,
  LogoImage,
  MobileActions,
  MobileOptions,
} from './Header.styles'

import { IoMenu, IoCloseOutline } from 'react-icons/io5'
import { LeadModel } from '../../../types/leadModel'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const Header = ({ SendLead, ip, userAgent }: Props) => {
  const [active, setActive] = useState(false)

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
    }

    const response = await SendLead(lead)

    return response
  }

  return (
    <Container id="header">
      <Content active={active}>
        <LogoImage
          href="/"
          active={active}
          onClick={() => HandleLead('click', 'header', 'logo')}
          onMouseOver={() => HandleLead('mouse-over', 'header', 'logo')}
        >
          <div />
          <p>BrBW</p>
          <div />
        </LogoImage>
        <Description>
          LandingPages e <br /> Serviços Intranet
        </Description>
        <Links>
          <Link
            href="#section-three"
            onClick={() => {
              HandleLead('click', 'header', 'link-section-three')
            }}
            onMouseOver={() =>
              HandleLead('mouse-over', 'header', 'link-section-three')
            }
          >
            Objetivo
          </Link>
          <Link
            href="#section-six"
            onClick={() => {
              HandleLead('click', 'header', 'link-section-six')
            }}
            onMouseOver={() =>
              HandleLead('mouse-over', 'header', 'link-section-six')
            }
          >
            Serviços
          </Link>
          <Link
            href="#section-seven"
            onClick={() => HandleLead('click', 'header', 'link-section-seven')}
            onMouseOver={() =>
              HandleLead('mouse-over', 'header', 'link-section-seven')
            }
          >
            Vantagens
          </Link>
          <Link
            href="#section-eight"
            onClick={() => HandleLead('click', 'header', 'link-section-eight')}
            onMouseOver={() =>
              HandleLead('mouse-over', 'header', 'link-section-eight')
            }
          >
            Sobre nós
          </Link>
          <Link
            href="https://wa.me/5511994497142?text=Olá,%20estou%20interessado%20em%20saber%20mais%20sobre%20soluções%20digitais,%20como%20Landing%20Pages,%20Intranet%20e%20outros%20serviços."
            onClick={() => HandleLead('click', 'header', 'link-section-five')}
            onMouseOver={() =>
              HandleLead('mouse-over', 'header', 'link-section-five')
            }
            target="__blank"
          >
            Contato
          </Link>
        </Links>

        <Icon>
          {active ? (
            <IoCloseOutline onClick={() => setActive(false)} />
          ) : (
            <IoMenu onClick={() => setActive(true)} />
          )}
        </Icon>
        <MobileOptions active={active}>
          {' '}
          <MobileActions>
            <Link
              href="#section-three"
              onClick={() => {
                setActive(false)
                HandleLead('click', 'header', 'link-section-three')
              }}
              onMouseOver={() =>
                HandleLead('mouse-over', 'header', 'link-section-three')
              }
            >
              Objetivo
            </Link>
            <Link
              href="#section-six"
              onClick={() => {
                setActive(false)
                HandleLead('click', 'header', 'link-section-six')
              }}
              onMouseOver={() =>
                HandleLead('mouse-over', 'header', 'link-section-six')
              }
            >
              Serviços
            </Link>
            <Link
              href="#section-seven"
              onClick={() => {
                setActive(false)
                HandleLead('click', 'header', 'link-section-seven')
              }}
              onMouseOver={() =>
                HandleLead('mouse-over', 'header', 'link-section-seven')
              }
            >
              Vantagens
            </Link>
            <Link
              href="#section-eight"
              onClick={() => {
                setActive(false)
                HandleLead('click', 'header', 'link-section-eight')
              }}
              onMouseOver={() =>
                HandleLead('mouse-over', 'header', 'link-section-eight')
              }
            >
              Sobre nós
            </Link>
            <Link
              href="https://wa.me/5511994497142?text=Olá,%20estou%20interessado%20em%20saber%20mais%20sobre%20soluções%20digitais,%20como%20Landing%20Pages,%20Intranet%20e%20outros%20serviços."
              onClick={() => {
                setActive(false)
                HandleLead('click', 'header', 'link-whatsapp')
              }}
              onMouseOver={() =>
                HandleLead('mouse-over', 'header', 'link-whatsapp')
              }
              target="__blank"
            >
              Contato
            </Link>
          </MobileActions>
        </MobileOptions>
      </Content>
    </Container>
  )
}
