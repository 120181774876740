import { useEffect, useState } from 'react'
import { HomeLayout } from '../components/layouts/home'
import { useLead } from '../hooks/useLead'
import { useUserIp } from '../hooks/useUserIp'
import { useLocation } from 'react-router-dom'

//Nos arquivos de tela, não faça de deinições de componentes ou distribuição de conteúdos
//Reserve o espaço para chamadas de API e/ou manipulação de dados

export default function Home() {
  const [ip, setIp] = useState('')

  const [latency, setLatency] = useState(0)

  const location = useLocation()

  const measurePageLoadTime = () => {
    window.addEventListener('load', () => {
      const [navigationTiming] = performance.getEntriesByType(
        'navigation'
      ) as PerformanceNavigationTiming[]

      if (navigationTiming) {
        const completeLoadTime =
          navigationTiming.loadEventEnd - navigationTiming.startTime
        setLatency(completeLoadTime)
      } else {
        console.error('Navegador não suporta PerformanceNavigationTiming')
      }
    })
  }

  const { SendLeadService } = useLead(location.pathname, latency)

  const { GetUserIp } = useUserIp()

  const HandleGetUserIp = async () => {
    setIp(await GetUserIp())
  }

  const userAgent = navigator.userAgent

  useEffect(() => {
    HandleGetUserIp()
    measurePageLoadTime()
  }, [])

  return <HomeLayout SendLead={SendLeadService} ip={ip} userAgent={userAgent} />
}
