import styled from 'styled-components'
import { screens } from '../../../types/screens'

export const Container = styled.section`
  width: 100%;
  height: auto;
  padding: 7rem 0 3rem;
  background: ${(props) => props.theme.colors.primary};
  display: grid;
  grid-template-columns: minmax(1.5rem, 1fr) minmax(auto, 12fr) minmax(
      1.5rem,
      1fr
    );
  grid-template-rows: repeat(4, auto);
  grid-template-areas:
    '..... contact .....'
    'line  line    line '
    '..... data    .....'
    '..... text    .....';

  ${screens.laptop`
    padding-top: 5rem;
      grid-template-columns: minmax(2.5rem, 1fr) minmax(auto, 12fr) minmax(
      2.5rem,
      1fr
    );
    `}

  ${screens.tablet`
      padding: 3rem 0;
      grid-template-columns: minmax(1.5rem, 1fr) minmax(auto, 10fr) minmax(
      1.5rem,
      1fr
    );
    `}
`

export const Contact = styled.p`
  grid-area: contact;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 1.25rem;
  color: ${(props) => props.theme.colors.white};

  ${screens.mobile`
     font-size: 1rem;
    );
    `}
`

export const Line = styled.div`
  grid-area: line;
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.colors.white};
  margin: 5rem 0 3rem;

  ${screens.mobile`
    margin: 2rem 0;
    );
    `}
`
export const Data = styled.div`
  grid-area: data;
  display: flex;
  justify-content: space-between;

  & > p {
    font-family: ${(props) => props.theme.fonts.primary};
    font-size: 1.125rem;
    color: ${(props) => props.theme.colors.white};
    text-decoration: underline;
  }

  ${screens.mobile`
      flex-direction: column;
      gap: 1.5rem;

      & > p {
          font-size: .875rem;
      }
    );
    `}
`

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const Socials = styled.div`
  display: flex;
  gap: 1.5rem;
`

export const Link = styled.a`
  all: unset;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 1.125rem;
  color: ${(props) => props.theme.colors.white};
  text-decoration: underline;
  cursor: pointer;

  ${screens.mobile`
      font-size: .875rem;
    );
    `}
`

export const Text = styled.p`
  grid-area: text;
  /* margin-top: 5rem; */
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 5.25rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.white};

  & > span {
    color: ${(props) => props.theme.colors.black};
  }

  ${screens.laptop`
      font-size: 3rem;
    );
    `}

  ${screens.tablet`
      font-size: 2rem;
    );
    `}
`
