import styled from 'styled-components'
import { screens } from '../../../../../types/screens'

export const Container = styled.section`
  width: 100%;
  overflow: hidden;
  display: grid;
  padding-bottom: 4rem;
  background: ${(props) => props.theme.colors.black};
  grid-template-columns: minmax(1.5rem, 1fr) minmax(auto, 12fr) minmax(
      1.5rem,
      1fr
    );
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    '..... content .....'
    'image image   image';

  ${screens.tablet`
      grid-template-columns:
        minmax(1.5rem, 1fr) minmax(auto, 10fr) minmax(1.5rem, 1fr);
      grid-template-rows: repeat(2, auto);
    `}
`

export const Content = styled.div`
  grid-area: content;
  padding: 12rem 0 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${screens.tablet`
      padding: 7.5rem 0 2rem;
      gap: 1.5rem;
    `}

  ${screens.mobile`
      padding: 6.5rem 0 2rem;
      gap: 1.5rem;
      text-align: left;
    `}
`

export const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 5rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.white};
  line-height: 6.25rem;
  margin-bottom: 1.5rem;

  & > span {
    font-family: ${(props) => props.theme.fonts.primary};
    font-size: 5rem;
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
    line-height: 6.25rem;
  }

  ${screens.laptop`
      font-size: 4rem;
      font-weight: bold;
      line-height: normal

       & > span {
        font-size: 4rem;
        font-weight: bold;
        line-height: normal
  }
    `}

  ${screens.tablet`
      font-size: 2.5rem;
      line-height: normal;
      margin-bottom: 0;

        & > span {
          font-size: 2.5rem;
          line-height: normal;
  }
    `}

  ${screens.mobile`
      font-size: 1.875rem;
      line-height: normal;
      margin-bottom: 0;
        & > br {
        display: none}

        & > span {
          font-size: 1.875rem;
          line-height: normal;
  }
    `}
`

export const Text = styled.p`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 1.125rem;
  max-width: 28rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.secondary};
  line-height: 1.5rem;
  margin-bottom: 2.75rem;

  ${screens.tablet`
      max-width: 28rem;
      margin-bottom: 2rem;
      font-size: 1rem;
    `}

  ${screens.mobile`
     max-width: 20rem;
     margin-bottom: 2rem;
    font-size: 1rem;
    `}
`

export const Action = styled.div`
  display: flex;
  gap: 1rem;

  ${screens.mobile`
      width: 100%;
      flex-direction: column;
      gap: 1rem;
    `}
`

export const ImageContainer = styled.div`
  grid-area: image;
  width: 100%;
  height: auto;
  max-height: 43rem;
  overflow: hidden;
`

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  transition: all 1s ease-in-out;

  &:hover {
    scale: 1.15;
  }
`
