import { ActiveButton, Button } from './SecondaryButton.styles'

interface Props {
  title: string
  onClick: () => void
  onMouseOver: () => void
}

export const SecondaryButton = ({ title, onClick, onMouseOver }: Props) => {
  return (
    <Button onClick={onClick} onMouseOver={onMouseOver} href="#section-six">
      <p>{title}</p>
      <ActiveButton />
    </Button>
  )
}
