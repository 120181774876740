import styled from 'styled-components'
import { screens } from '../../../types/screens'

export const Button = styled.a`
  all: unset;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 12.125rem;
  height: 3.5rem;
  border-radius: 9999px;
  background: ${(props) => props.theme.colors.primary};
  cursor: pointer;

  & > p {
    position: absolute;
    color: ${(props) => props.theme.colors.white};
    font-weight: 500;
    text-align: center;
    z-index: 2;
    transition: all 0.3s ease-in-out;
  }

  ${screens.mobile`
    width: 100%;
    `}

  &:hover {
    & > p {
      color: ${(props) => props.theme.colors.primary};
    }
    & > :last-child {
      left: 0;
    }
  }
`

export const ActiveButton = styled.div`
  position: absolute;
  border-radius: 9999px;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.white};
  z-index: 0;
  top: 0;
  left: 100%;
  transition: all 0.3s ease-in-out;
`
