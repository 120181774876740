import { useEffect, useRef, useState } from 'react'
import useTimeSpentInSection from '../../../../../hooks/useTimeSpentInSection'
import { LeadModel } from '../../../../../types/leadModel'
import { Container, Image } from './SectionFive.styles'

import SectionFiveImage from '@/../../src/assets/images/section-five-image.png'

interface Props {
  SendLead: (lead: LeadModel) => Promise<void>
  ip?: string
  userAgent?: string
}

export const HomeSectionFive = ({ SendLead, ip, userAgent }: Props) => {
  const timeSpent = useTimeSpentInSection('section-five')

  const [opacity, setOpacity] = useState(0)

  const sectionRef = useRef<HTMLDivElement>(null)

  const HandleLead = async (
    interaction_type: string,
    session_id: string,
    element_id: string,
    duration_on_page_seconds?: number
  ) => {
    const lead: LeadModel = {
      site: '',
      interaction_type,
      session_id,
      element_id,
      ip_address: ip,
      user_agent: userAgent,
      duration_on_page_seconds,
    }

    const response = await SendLead(lead)

    return response
  }

  useEffect(() => {
    if (timeSpent)
      HandleLead(
        'time-spent',
        'section-five',
        'section-five-container',
        timeSpent / 1000
      )
  }, [timeSpent])

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const sectionTop = sectionRef.current.getBoundingClientRect().top
        const windowHeight = window.innerHeight

        if (sectionTop < windowHeight && sectionTop > 0) {
          const visiblePercentage = 1 - sectionTop / windowHeight

          setOpacity(visiblePercentage)
        } else if (sectionTop < 0) {
          setOpacity(1)
        } else {
          setOpacity(0)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Container id="section-five" ref={sectionRef}>
      <Image
        src={SectionFiveImage}
        alt="section-five-image"
        opacity={opacity}
      />
    </Container>
  )
}
